/**
 * 実行クラス
 */
new (class Shutto {

  /**
   * 言語
   */
  private lang: string = 'ja';

  /**
   * コンストラクタ
   */
  constructor() {
    __stt.on('setLangHit', (event: any): void => {
      this.lang = event.lang;
      document.getElementsByTagName('html')[0].lang = event.lang;
      this.setImageLang();
      // this.setBoatraceUrl(); //Shutto側で対応可
    });
  }

  /**
   * 画像の多言語対応
   */
  private setImageLang() {
    const imgs = document.querySelectorAll('.fn-imageLang source, .fn-imageLang img');
    const suffix = (this.lang == 'ja') ? '.png': '@' + this.lang + '.png';
    Array.prototype.forEach.call(imgs, function (img: HTMLElement, i: number) {
      let src = img.getAttribute('src') || img.getAttribute('srcset')!;
      let new_src = src.replace(/(@.*)?\.png/, suffix);
      if (img.getAttribute('src') != null) {
        img.setAttribute('src', new_src);
      } else {
        img.setAttribute('srcset', new_src);
      }
    });
  }

  /**
   * URLの多言語対応
   */
  private setBoatraceUrl() {
    const el = document.querySelector('.header__race li:nth-child(1) a')!;
    const url = () => {
      if (this.lang == 'en') return 'http://www.boatrace-fukuoka.com/modules/lang/?page=index_en_top';
      if (this.lang == 'zh-CN') return 'http://www.boatrace-fukuoka.com/modules/lang/?page=index_cs_top';
      if (this.lang == 'zh-TW') return 'http://www.boatrace-fukuoka.com/modules/lang/?page=index_ct_top';
      if (this.lang == 'ko') return 'http://www.boatrace-fukuoka.com/modules/lang/?page=index_kr_top';
      return 'http://www.boatrace-fukuoka.com/';
    };
    el.setAttribute('href', url());
  }
})();
